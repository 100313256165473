#projects {
  text-align: left;
  .section-content {
    display: flex;
    @media only screen and (max-width: 600px) {
      display: block;
    }
  }
  h2 {
    margin: 0px;
    text-align: left;
    font-weight: 300;
    padding: 20px;
    color: white;
    background-color: #009688;
  }
  .underline {
    border-bottom: 5px solid #009688;
  }
  .primary-color {
    color: #009688;
  }
  .card {
    margin: 1rem 2rem;
    @media only screen and (max-width: 600px) {
      margin: 1rem;
    }
  }
  .card-image {
    border-bottom: 1px solid rgba(160,160,160,0.2);
  }
  p.flow-text{
    font-size: 1rem;
  }
}

