@import './variables.scss';

html {
    scroll-behavior: smooth;
  }

.App {
    text-align: center;
  }

section {
    padding-bottom: 4rem;
    h2 {
        margin-bottom: 4rem !important;
    }
}

.yellow {
  color: $yellow;
}