#education {
    text-align: left;
  h2 {
    margin: 0px;
    text-align: left;
    font-weight: 300;
    padding: 20px;
    color: white;
    background-color: #009688;
  }
  .underline {
    border-bottom: 5px solid #009688;
  }
  .teal {
    background-color: #009688;
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}