#experience {
  text-align: left;
  h2 {
    margin: 0px;
    text-align: left;
    font-weight: 300;
    padding: 20px;
    color: white;
    background-color: #009688;
  }
  .underline {
    border-bottom: 5px solid #009688;
  }
  .teal {
    background-color: #009688;
  }
  .card-content {
    .row {
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
    }
    .company-logo {
      width: 50px;
    }
    .card-title {
      margin-bottom: 0px;
    }
    ul {
      padding: 0 30px;
    }

    li {
      list-style: none;
    }

    li:before {
      font-family: AppleGothic, "Lucida Sans", Impact, Verdana, sans-serif;
      content: "• ";
      color: #009688;
      float: left;
      width: 1em;
      margin: -0.25em -1.2em;
      font-size: 25px;
    }
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
