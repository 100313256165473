.drawer {

    main {
        font-family: "Roboto", sans-serif;
        width: 100%;
    }
    
    .name-logo {
        h1 {
            font-size: 32px;
            text-align: center;
            font-weight: 100;
            color: #009688;
            padding: 0;
            margin: 0;
        }
        padding: 0px 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    
    .icon-anchor {
        color: black;
    }
    
    // THINGS YOU SHOULD NEVER DO :)
    .makeStyles-drawerPaper-4 {
        width: 190px !important;
    }
    .makeStyles-drawer-3 {
        width: 190px !important;
    }
    .MuiListItemIcon-root {
        min-width: 40px !important;
    }
    .MuiTypography-body1 {
        font-weight: 100 !important;
    }
    .side-bar-nav-list {
        .material-icons {
            width: 100% !important;
        }
    }
    .MuiIcon-root {
        text-align: center;
    }
    .MuiListItemText-root {
        margin-bottom: 0px !important; 
        padding: 0 4px;
    }
    
    .MuiListItem-root {
        padding: 10px 16px !important;
    }
}

.nav-layout-stacked {
    display: none;
}

@media (max-width: 767px) {
    .drawer {
        display: none;
    }
    .nav-layout-left {
        display: none;
    }
    .nav-layout-stacked {
        display: block;
        .makeStyles-drawerPaper-3 {
            width: 250px;
            .name-logo {
                h1 {
                    font-size: 42px;
                }
            }
            .MuiTypography-overline {
                font-size: 1rem;;
            }
            ul .MuiListItem-root {
                padding: 20px 16px !important;
                .MuiTypography-body1 {
                    font-size: 1.5rem;
                }
            }
        }
    }
}