#intro {
    background-image: url('../../../images/hero-canada.JPG');
    background-position-y: bottom;
    background-size: cover;
    width:100%;
    height: 100vh;
}

.mobile {
    display: none;
    @media (max-width: 767px) {
        display: block;
        display: flex;
        align-items: center;
        font-size: 42px;
    }
}

.hero-container {
    position: relative;
    .hero-text {
        margin: 0px;
        text-align: left;
        font-weight: 300;
        line-height: 150%;
        padding: 30px;
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
        color: white;
        z-index: 999;

        @media (max-width: 767px) {
            padding: .5rem;
        }
    }
    .underline {
        border-bottom: 5px solid #009688;
    }
    .teal {
        background-color: #009688;
    }
}

.overlay {
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

@media (max-width: 1050px) {
    #intro {
        background-position-x: 65%;
    }
}
