main {
    font-family: "Roboto", sans-serif;
    width: 100%;
}

.flex {
    display: flex;
}

.name-logo {
    font-size: 32px;
    text-align: left;
    padding: 0px 20px;
    font-weight: 100;
    color: #009688;
}

.icon-anchor {
    color: black;
}

// THINGS YOU SHOULD NEVER DO :)
.makeStyles-drawerPaper-4 {
    width: 190px !important;
}
.makeStyles-drawer-3 {
    width: 190px !important;
}
.MuiListItemIcon-root {
    min-width: 40px !important;
}
.MuiTypography-body1 {
    font-weight: 100 !important;
}
.side-bar-nav-list {
    .material-icons {
        width: 100% !important;
    }
}
.MuiIcon-root {
    text-align: center;
}
.MuiListItemText-root {
    margin-bottom: 0px !important; 
    padding: 0 4px;
}

.MuiListItem-root {
    padding: 10px 16px !important;
}
